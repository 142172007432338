<template>
	<!-- Ezafeh kardane class wa tarahiye classe slide -->
	<article class="slide col-12-xs col-12-sm col-12-mm col-12-md col-12-lg col-12-xl col-12-xx">
		<!-- Ezafeh kardane tranistion baraye ejraye animation -->
		<transition name="slide">
			<slot />
		</transition>
	</article>
</template>

<script> 
	export default {}
</script>

<style scoped lang="scss">
	.slide-enter-active,
	.slide-leave-active {
		transition: opacity 1s ease-in-out;
	}
	.slide-enter-from,
	.slide-leave-to {
		opacity: 0;
	}
	.slide-enter-to,
	.slide-leave-from {
		opacity: 1;
	}
</style>
