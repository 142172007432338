<template>
	<article
		class="col-12-xs col-12-sm col-12-mm col-12-md col-12-lg col-12-xl col-12-xx p-3"
	>
		<h2 class="h2">Verven</h2>
		<p class="lead first">
			{{ txt }}
		</p>
	</article>
</template>

<script>
	export default {
		name: 'Verven',

		data() {
			return {
				txt:
					'De een verft zijn haar om grijze haren te verbloemen, de ander omdat hij toe is aan een andere kleur. Wat wilt u?Wij helpen u hier altijd mee om mooi haar te hebben.',
			}
		},
	}
</script>

