<template>
	<article class="grid">
		<section>
			<h3 class="h2">Altijd goede service</h3>
			<!-- <i class="fa fa-check"></i> -->
			<img src="@/assets/shapes/service.svg" alt="" class="img" />
	
		</section>
		<section>
			<h3 class="h2">Klanttevredenheid</h3>
			<img src="@/assets/shapes/like.svg" alt="" class="img" />
	
		</section>
		<section>
			<h3 class="h2">Met of zonder afspraak</h3>
			<img src="@/assets/shapes/no-time.svg" alt="" class="img" />
	
		</section>
	</article>
</template>

<script>
	export default {
		name: 'Icons',
	}
</script>

<style scoped lang="scss">
	.grid {
		width: auto;
		background-color: rgb(255, 218, 218);
		margin: 10px auto;
		padding: 40px;
		border-radius: 7px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);

		@media (max-width: 780px) {
			grid-template-columns: 1fr;
			width: auto;
			max-width: 100%;
			margin: 0 auto;
		}

		section {
			display: block;
			p {
				width: 300px;
				margin: 0 auto;
				padding: 10px;

				@media (max-width: 780px) {
					width: auto;
				}
			}
		}

		h3 {
			font-size: 26px;
			margin-bottom: 20px;
			@media (max-width: 480px) {
				margin-top: 50px;
				margin-bottom: 10px;
			}
		}

		.fa {
			font-size: 50px;
			color: $secondary;
		}

		.img {
			width: 220px;
			height: 150px;
		}
	}
</style>
