<template>
	<article>
		<Gegevens />

		<hr />

		<iframe
			src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d630821.7400859698!2d4.940538931246476!3d51.858264679615814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1snl!2snl!4v1627587419715!5m2!1snl!2snl"
			style="border:0;"
			allowfullscreen="true"
			loading="lazy"
			class=" p-3 col-12-xs col-12-sm col-12-mm col-12-md col-12-lg col-12-xl col-12-xx"
			height="600px"
		></iframe>

		<Icons />
	</article>
</template>

<script>
	import Gegevens from '@/components/Gegevens.vue'
	import Icons from '@/components/Icons.vue'
	export default {
		name: 'Contact',

		components: {
			Gegevens,
			Icons,
		},
	}
</script>

<style scoped lang="scss">
	article {
		width: 100%;
	}
</style>
