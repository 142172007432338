<template>
	<article class="">
		<Carousel
			class="carousel"
			v-slot="{ currentSlide }"
			:time="5000"
			:play="true"
			:nav="true"
			:pagin="true"
		>
			<!-- Mohatawaye Slot -->
			<Slide v-for="(s, i) in carSlides" :key="i">
				<div v-show="currentSlide === i + 1" class="slide-info">
					<img :src="getUrlImg(s)" :alt="s" />
				</div>
			</Slide>
		</Carousel>

		<br />

		<Baner />

		<br />
		<Icons />
	</article>
</template>

<script>
	// @ is an alias to /src
	import Carousel from '@/components/Carousel.vue'
	import Slide from '@/components/Slide.vue'
	import Icons from '@/components/Icons.vue'
	import Baner from '@/components/Baner.vue'

	export default {
		name: 'Home',
		data() {
			return {
				carSlides: [
					'Grafic 5',
					'Haar-3',
					'Haar-Wassen',
					'Greedschap-1',
					'Greedschap-2',
					'Grafic (2)',
				],
			}
		},
		components: {
			Carousel,
			Slide,
			Icons,
			Baner,
		},
		methods: {
			getUrlImg(img) {
				return require(`@/assets/img/${img}.jpg`)
			},
		},
	}
</script>

<style lang="scss" scoped>
	.container {
		width: 100%;
		max-width: 100%;

		@media (max-width: 480px) {
			width: auto;
			max-width: 480px;
			// margin: 0 0 20px 0;
		}
	}

	.carousel {
		width: 100%;
		height: 90vh;
		z-index: 0;
		@media (max-width: 480px) {
			width: auto;
			max-width: 480px;
			// max-height: 90vh;
			// z-index: 1;
		}

		.slide-info {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			max-height: 100%;

			@media (max-width: 480px) {
				width: auto;
				max-width: 480px;
				height: 100%;
				max-height: 80vh;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				@media (max-width: 480px) {
					width: 100%;
					height: 100%;
					max-width: 480px;
				}
			}
		}
	}
</style>
