export default {
	created() {
		this.toTop()
	},

	data() {
		return {
			services: [
				{
					title: 'Volume Lash Lifting',
					link: 'Volume',
				},
				{
					title: 'Botox',
					link: 'Botox',
				},
				{
					title: 'Keratinebehandeling',
					link: 'Keratine',
				},
				{
					title: 'Gechicht Epileren',
					link: 'Gezicht Epileren',
				},
				{
					title: 'Epileren',
					link: 'Epileren',
				},
				{
					title: 'Hena',
					link: 'Hena',
				},
				{
					title: 'Extentions',
					link: 'Extentions',
				},
				{
					title: 'Knippen',
					link: 'Knippen',
				},
				{
					title: 'Verven',
					link: 'Verven',
				},
				{
					title: 'Highlight',
					link: 'Highlight',
				},
			],
		}
	},

	methods: {
		toTop() {
			// console.log('Anne mixi')
			/* 	if (e) {
				return e.target
			} */

			window.scrollTo(0, 0)
		},
	},
}
