<template>
	<div class="alert bg-success" v-if="aded">
		<h2 class="text-light">Bedankt</h2>
		<p class="text-light lead">
			Uw bericht is verzonden en wij nemen snel met u contact op.
		</p>
	</div>
	<article class="row justify-center p-3">
		<section
			class="col-12-xs col-12-sm col-12-mm col-6-md col-6-lg col-6-xl col-6-xx mt-5"
		>
			<p class="p-1">
				<i class="fas fa-envelope "></i> Email <br />
				<a href="mailto:info@gazellebeauty.nl" class="text-primary">
					info@gazellebeauty.nl</a
				>
			</p>
			<p class="p-1">
				<i class="fas fa-phone"></i> Mobile <br />
				<a href="tel:0031616498029" class="text-primary"> 0616498029</a>
			</p>

			<div class="socials">
				<h2 class="h2">Volg ons op</h2>
				<div class="">
					<a href="tel:0031616498029" target="_blank"
						><i class="fab fa-whatsapp fa-2x mx-1"></i
					></a>
					<a href="" target="_blank"
						><i class="fab fa-facebook fa-2x mx-1"></i
					></a>
					<a
						href="https://www.instagram.com/gazellebeauty/"
						target="_blank"
						><i class="fab fa-instagram fa-2x mx-1"></i
					></a>
				</div>
			</div>
		</section>

		<section
			class="col-12-xs col-12-sm col-12-mm col-6-md col-6-lg col-6-xl col-6-xx"
		>
			<p class="lead mb-3">U kunt altijd een bericht sturen</p>
			<form>
				<label for="naam">Naam</label>
				<input type="text" v-model="naam" required /> <br />
				<label for="email">Email</label>
				<input type="email" v-model="email" required /> <br />
				<label for="tel">Telefoon</label>
				<input type="tel" v-model="tel" required /> <br />
				<textarea
					name="textarea"
					id="area"
					cols="60"
					rows="10"
					v-model="text"
				>
				</textarea>

				<button type="submit" @click.prevent="verzend" class="btn">
					Verzenden
				</button>
			</form>
		</section>
	</article>
</template>

<script>
	import service from '@/service/GazelService'
	export default {
		name: 'Gegevens',

		data() {
			return {
				naam: '',
				email: '',
				tel: null,
				text: '',
				aded: false,
			}
		},
 
		methods: {
			verzend() {
				// Nieuw player defeniëren
				const newKarbar = {
					naam: this.naam,
					email: this.email,
					tel: this.tel,
					text: this.text,
				}

				// Karbar toevoegen
				service.addKarbar(newKarbar)

				// Bericht tonen
				this.aded = true

				// Een vertraging maken
				setTimeout(() => {
					this.$router.push('/')
				}, 3000)

				/*else {
					console.log('Minimaal 3 characters is toegestaand')
				} */
			},
		},
	}
</script>

<style scoped lang="scss">
	article {
		section {
			@include xxl {
				margin: 0;
				width: 100%;
			}
		}

		.text-primary {
			font-size: 40px;

			@include sm {
				font-size: $m-font;
			}
			@include xxl {
				font-size: $xxl-font;
			}
		}

		width: 100%;

		textarea {
			width: 100%;
			margin-top: 10px;
		}
	}

	.alert {
		display: flex;
		flex-direction: column;
		width: 100%;
		top: 100px;
		position: fixed;

		p {
			line-height: 1.8;
		}
	}

	.socials {
		margin: 30px 0;

		h2 {
			color: $primary;
			margin: 0 0 20px 0;
		}

		.fa {
			padding-top: 2px;
			margin: 0 15px;
			font-size: 40px;
		}

		.fa-facebook {
			color: #1877f2;
		}
		.fa-whatsapp {
			color: #128c7e;
		}
		.fa-instagram {
			color: #833ab4;
		}
	}
</style>
