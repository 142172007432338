<template>
	<article class="row justify-center">
		<section
			class="col-12-xs col-12-sm col-12-mm col-6-md col-6-lg col-6-xl col-6-xx"
		>
			<img src="@/assets/img/Woman-1.jpg" alt="Img-baner" class="img" />
		</section>
		<section
			class="pt-3 col-12-xs col-12-sm col-12-mm col-6-md col-6-lg col-6-xl col-6-xx"
		>
			<h2 class="h2">Een kapsalon voor allemaal</h2>
			<p class="lead">
				Vrouwen, mannen en kinderen zijn allemaal welkom in onze
				kapsalon.
			</p>

			<router-link class="btn btn-primary mt-4" :to="{ name: 'Vrouw' }"
				>Meer lezen</router-link
			>
		</section>
	</article>
</template>

<script>
	export default {
		name: 'Baner',
	}
</script>

<style scoped lang="scss"></style>
