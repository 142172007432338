import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth' 

// Your web app's Firebase configuration
var firebaseConfig = {
	apiKey: 'AIzaSyAX1g7WEaoO3UZYxSNPCWo1DhwsfWVm4WA',
	authDomain: 'gazallebeauty.firebaseapp.com',
	projectId: 'gazallebeauty',
	storageBucket: 'gazallebeauty.appspot.com',
	messagingSenderId: '82608038526',
	appId: '1:82608038526:web:593518ded4d143c8a2ae2f',
}
// Initialize Firebase
export const db = firebase.initializeApp(firebaseConfig).firestore()

export const saveUser = firebase.auth()
