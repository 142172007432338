<template>
	<article class="justify-center row">
		<section class="p-1 row">
			<span class="naam">{{ naam }}</span>
			<span class="euro ">&euro;{{ prijs }}</span>
		</section>
	</article>
</template>

<script>
	export default {
		name: 'Tarief',

		data() {
			return {
				werk: 'Epileren',
			}
		},

		props: {
			naam: {
				type: String,
				required: true,
			},
			prijs: {
				type: String,
				required: true,
			},
		},

		components: {},

		methods: {},
		computed: {},
	}
</script>

<style scoped lang="scss">
	article {
		min-width: 350px;
		height: auto;
		background-color: $primary;
		transform: scale(0.9);
		display: grid;
		grid-template-columns: 3fr 1fr;
		justify-content: space-evenly;
		border-radius: 7px;
		overflow-x: hidden;
		border: red 1px solid;

		&:hover {
			transform: scale(1);
			transition: scale 2s 0.5s ease-in-out;
		}

		section {
			width: auto;
			height: auto;
		}
		.euro {
			font-size: 20px;
			color: #000;
			width: 100%;
			float: right;
			top: 40%;
			right: 0;
			left: 40%;
			position: absolute;
			font-weight: bold;
			justify-self: flex-end;
		}

		.naam {
			color: #000;
			width: 200px;
			font-weight: bold;
			text-align: left;
			font-size: 20px;
			margin-left: -20px;
		}
	}
</style>
