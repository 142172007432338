<template>
	<article class=" grid-2">
		<section class="sec-aks">
			<img src="@/assets/img/Makeup3.jpg" class="img" alt="OvermijFoto" />
		</section>
		<section class="sec-txt">
			<h2>Overmij</h2>
			<p>
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio
				iste harum fuga amet quod ratione labore, ea inventore quam
				fugiat. Accusamus deserunt suscipit qui alias nostrum, iste vel
				quidem provident incidunt eveniet expedita ab sed, iusto aut
				veritatis dolorum eligendi saepe doloribus ipsum voluptatibus
				odit asperiores doloremque explicabo consectetur. Non
				perspiciatis voluptate sunt, illo, hic magni quasi debitis vitae
				ad aut quas voluptatum provident reiciendis impedit sit, sequi
				voluptas ullam ipsa quisquam. Corporis, eum architecto quia id
				officia ad voluptatibus possimus voluptate atque. Vel, rerum
				itaque nisi, officiis, voluptatem aperiam repellendus eos magnam
				amet perferendis fugit a libero consectetur placeat.
			</p>
		</section>
	</article>
</template>

<script>
	export default {
		name: 'Overmij',

		data() {
			return {}
		},

		components: {},

		methods: {},
		computed: {},
	}
</script>

<style lang="scss" scoped>
	article {
		// width: 100%;
		// height: auto;
		background-color: rgba(19, 13, 13, 0.178);
		// margin: auto;
		// padding: 50px;
		// display: grid;
		// grid-template-columns: 1fr 1.5fr;

		.sec-aks {
			width: 100%;
			img {
				margin-top: 50px;
				border-radius: 7px;
			}
		}

		.sec-txt {
			width: 100%;
			p {
				padding: 30px;
				line-height: 2;
				font-size: 20px;
			}
		}
	}

	// Media voor Mobiles
	@media screen and (max-width: 480px) {
		.grid-2 {
			grid-template-columns: 1fr;
			width: auto;
			max-width: 450px;
			padding-bottom: 50px;
			margin: 10px auto;
		}

		.sec-txt { 
			grid-row-start: 1;
			padding: 0 20px;
		}

		.sec-aks {
			grid-row-start: 2;
		}

		.img {
			width: 100%;
			max-width: 300px;
			height: auto;
			margin: 0 auto;
		}
	}
</style>
