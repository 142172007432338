<template>
	<article
		class="col-12-xs col-12-sm col-12-mm col-12-md col-12-lg col-12-xl col-12-xx p-3"
	>
		<h2 class="h2">Knippen</h2>
		<p class="lead first">{{ txt }}</p>
	</article>
</template>

<script>
	export default {
		name: 'Knippen',

		data() {
			return {
				txt:
					'Haar knippen doen de kappers met een schaar. Door het haar te knippen kan je hairstylist recht knippen en mooie, strakke lijnen zetten. De kappersschaar wordt ook wel als een mesje gebruikt door ermee te snijden. U krijgt er mooi en gezond haar van.',
			}
		},
	}
</script>

