<template>
	<header class="navbar-primary">
		<article class="container">
			<!-- :class="{ 'scrol-nav': scrollNav }" -->

			<!-- Logo -->
			<div class="logo">
				<router-link to="/"
					><img src="@/assets/Logo-zonder.png" class="img" alt="Logo"
				/></router-link>
			</div>

			<section v-if="mobile">
				<!-- Hamburger Menu Icon v-if="mobile"-->
				<a
					target="_blank"
					href="https://widget.treatwell.nl/salon/416594/menu/"
					class="afspraakMobile"
					>Afspraak maken</a
				>

				<transition name="iconNav">
					<div class="iconNav" @click.prevent="toggleMobilNav">
						<span v-if="iconOpen">
							<i class="fas fa-bars fa-2x text-dark ham"></i>
						</span>
						<span v-if="!iconOpen">
							<i class="fas fa-times fa-2x text-dark ham"></i>
						</span>
					</div>
				</transition>
				<!-- Animation Mobile Menu -->
				<transition name="mobileNav">
					<!-- v-show if mobile -->
					<ul class="mobileNav mt-3" v-if="mobileNav">
						<div @click="openMobMenu">
							<span v-if="vrouwMenu">
								<i
									class="fas fa-minus text-dark ham"
									id="plus"
								></i>
							</span>
							<span v-if="!vrouwMenu">
								<i
									class="fas fa-plus  text-dark ham"
									id="plus"
								></i>
							</span>
						</div>

						<li v-for="(b, a) in menuData" :key="a">
							<router-link
								class="nav-link"
								:to="{ name: b.link }"
							>
								{{ b.title }}
							</router-link>
						</li>
						<div v-if="vrouMenuOpen">
							<span v-for="(m, n) in megaMenu" :key="n">
								<DropMenuItem :items="m" class="menu-plus" />
								<!-- <DropMenuItem :items="m.vroServ" class="menu-plus" />
								<DropMenuItem :items="m.kinServ" class="menu-plus" /> -->
							</span>
						</div>
					</ul>
				</transition>
			</section>

			<nav v-if="!mobile">
				<!-- Desktop Nav -->
				<ul class="deskNav">
					<a
						target="_blank"
						href="https://widget.treatwell.nl/salon/416594/menu/"
						class="afspraakDesktop"
						>Afspraak maken</a
					>

					<li v-for="(a, ind) in menuData" :key="ind">
						<!-- :title="`Naar ${a.title}`" -->
						<router-link class="nav-link" :to="{ name: a.link }"
							>{{ a.title }}
						</router-link>
						<div v-if="!a.open" @mouseover="openBehandeling">
							<i
								id="angor"
								class="fas"
								:class="[
									!vrouwMenu
										? ' fa-caret-down'
										: ' fa-caret-up',
								]"
							>
							</i>

							<div class="" v-if="behandelMenu">
								<span
									class="drop"
									v-for="(b, e) in megaMenu"
									:key="e"
								>
									<DropMenuItem :items="b" class="col" />
								</span>
							</div>
						</div>
					</li>
				</ul>
			</nav>
		</article>
	</header>
</template>

<script>
	import DropMenuItem from '@/components/DropMenuItem'
	export default {
		name: 'Navigatie',
		data() {
			return {
				scrollNav: null, //voor scroll
				mobile: null, // mobile staand
				mobileNav: null, // Mobile nav
				windowWidth: null, // bepalen wat het innerWidth is
				iconOpen: null,
				dropDwon: null,
				vrouMenuOpen: null,
				vrouwMenu: null,
				manMenu: null,
				behandelMenu: null,

				menuData: [
					{
						title: 'Home',
						link: 'Home',
						open: true,
					},
					{
						title: 'Tarieven',
						link: 'Tarieven',
						open: true,
					},

					{
						title: 'Behandelingen',
						link: 'Vrouw',
						// open: false,
					},

					{
						title: 'Contact',
						link: 'Contact',
						open: true,
					},
				],

				megaMenu: {
					services: [
						{
							title: 'Volume Lash Lifting',
							link: 'Volume',
						},
						{
							title: 'Botox',
							link: 'Botox',
						},
						{
							title: 'Keratinebehandeling',
							link: 'Keratine',
						},
						{
							title: 'Gechicht Epileren',
							link: 'Gezicht Epileren',
						},
						{
							title: 'Epileren',
							link: 'Epileren',
						},
						{
							title: 'Hena',
							link: 'Hena',
						},
						{
							title: 'Extentions',
							link: 'Extentions',
						},
						{
							title: 'Knippen',
							link: 'Knippen',
						},
						{
							title: 'Verven',
							link: 'Verven',
						},
						{
							title: 'Highlight',
							link: 'Highlight',
						},
					],
				},
			}
		},

		components: {
			DropMenuItem,
		},

		// Watch
		watch: {
			$route() {
				this.mobileNav = false
				this.iconOpen = true
				this.vrouwMenu = false
				this.vrouMenuOpen = false
				this.behandelMenu = false
			},
		},

		// Live Cycle Hook
		created() {
			// Call function voor screen
			window.addEventListener('resize', this.checkScreen)
			this.checkScreen()
		},

		mounted() {
			// Call function voor scroll
			window.addEventListener(
				'scroll',
				this.updateScroll
			) /*
			window.addEventListener('mouseenter', this.subMenu)
			window.addEventListener('mouseleave', this.subMenuOut) */
		},

		methods: {
			// Check screen function
			checkScreen() {
				this.windowWidth = window.innerWidth
				if (this.windowWidth <= 768) {
					this.mobile = true
					this.iconOpen = true
					return
				}
				this.mobile = false
				this.mobileNav = false
				return
			},

			// Toggle function
			toggleMobilNav() {
				this.mobileNav = !this.mobileNav

				if (this.mobileNav) {
					this.iconOpen = false
				} else {
					this.iconOpen = true
				}
			},

			openMobMenu() {
				this.vrouMenuOpen = !this.vrouMenuOpen
				if (this.vrouMenuOpen) {
					this.vrouwMenu = true
				} else {
					this.vrouwMenu = false
				}
			},

			openBehandeling() {
				this.behandelMenu = !this.behandelMenu
				if (this.behandelMenu) {
					this.vrouwMenu = true
					this.manMenu = true
				} else {
					this.vrouwMenu = false
					this.manMenu = false
				}
			},

			// A function for scroll behavior
			// updateScroll() {
			// 	const scrollPosition = window.scrollY
			// 	if (scrollPosition > 0) {
			// 		window.innerHeight = 'auto'
			// 		return (this.scrollNav = true)
			// 	}
			// 	this.scrollNav = false
			// },
		},

		computed: {
			iconObject() {
				if (this.iconOpen) {
					return 'fas fa-times'
				} else return 'fas fa-bars'
			},
		},
	}
</script>

<style lang="scss" scoped>
	// Animation Mobile Nav
	.mobileNav-enter-active,
	.mobileNav-leave-active {
		transition: 1s ease-in-out all;
	}
	// Nav komt vanuit
	.mobileNav-enter-from,
	.mobileNav-leave-to {
		transform: translateX(-250px);
	}
	// Nav gaat terug
	.mobileNav-enter-to {
		transform: translateX(0);
	}

	// Menu hamburger animation
	// .iconNav-enter-active,
	// .iconNav-leave-active {
	// 	transition: opacity 1s ease-in-out;
	// }
	// .iconNav-enter-from,
	// .iconNav-leave-to {
	// 	opacity: 0;
	// }
	// .iconNav-enter-to {
	// 	opacity: 1;
	// }

	// Class dia na scrol gaat op zijn kracht
	// 	.scrol-nav {
	// 		background-color: $navColor;
	// 		box-shadow: 0 4px 4px -1px #c29947, 0 2px 2px -1px #c29947;
	// 		height: 70px;
	// 		z-index: 0;
	//
	// 		.logo {
	// 			padding-top: 20px;
	// 			.img {
	// 				width: 130px;
	// 				height: 70px;
	// 			}
	// 		}
	//
	// 		nav {
	// 			padding-top: 5px;
	// 			padding-left: 5px;
	// 		}
	// 	}
</style>
