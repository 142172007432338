<template>
	<article
		class="col-12-xs col-12-sm col-12-mm col-12-md col-12-lg col-12-xl col-12-xx p-3"
	>
		<h2 class="h2">{{ this.$route.meta.title }}</h2>
		<p class="lead first">
			Epileren is het verwijderen van haar met inbegrip van de haarwortel.
			<br />De haartjes kunnen na het epileren ook wegblijven terwijl dit niet
			de bedoeling was. Met permanente make-up kunnen er weer haartjes
			getekend worden, zodat de wenkbrauw voor een paar jaar weer een
			mooie vorm krijgt.
		</p>
	</article>
</template>

<script>
	export default {
		name: 'Epileren',

		data() {
			return {}
		},

		components: {},

		methods: {},

		computed: {},
	}
</script>

<style scoped lang="scss"></style>
