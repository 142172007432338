<template>
	<article class="col-12-xs col-12-sm col-12-mm col-6-md col-6-lg col-6-xl col-6-xx justify-center p-3">
		<h2 class="h2">Haar Extention</h2>
		<p class="lead first">
			De Haarextensions met natuur haar <br> 👉🏽👉🏽Artificial hair
			integrations, more commonly known as hair extensions or hair weaves,
			add length and fullness to human hair. Hair extensions are usually
			clipped, glued, or sewn on natural hair by incorporating additional
			human or synthetic
		</p>
	</article>
</template>

<script>
	export default {
		name: 'Extention',

	
		
	}
</script>
