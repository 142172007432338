<template>
	<article class="p-1">
		<div
			class="col-12-xs col-12-sm col-12-mm col-12-md col-6-lg col-4-xl col-3-xx div"
			v-for="(a, ind) in services"
			:key="ind"
		>
			<router-link :to="{ name: a.link }" class="text-primary">{{
				a.title
			}}</router-link>
		</div>
	</article>
</template>

<script>
	export default {
		name: 'Vrouw',


	}
</script>

<style scoped lang="scss">
	article {
		width: 100%;
	}
</style>
