import { createStore } from 'vuex'

export default createStore({
	state: {
		navigatie: [
			{
				title: 'Home',
				baz: true,

				subMenu: [
					{
						title: 'BacheEen',
						baz: false,
					},
				],
			},

			{
				title: 'Mannen',
				baz: false,
			},
			{
				title: 'Vrouwen',
				baz: false,
			},
			{
				title: 'Kinderen',
				baz: false,
			},
		], // Einde Navigatie
	},

	getters: {
		navi: state => {
			return state.navigatie
		},
	},
	mutations: {},
	actions: {},
	modules: {},
})
