<template>
	<article>
		<Navigatie class="op" />
		<router-view class="view" />

		<Footer />
	</article>
</template>

<script>
	import Navigatie from '@/components/Navigatie'
	import Footer from '@/components/Footer'

	export default {
		components: {
			Navigatie,
			Footer,
		},

		data() {
			return {
				hame: null,
			}
		},
	}
</script>

<style lang="scss">
	#app {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		width: 100%;
		height: auto;
	}

	.op {
		z-index: 9999;
	}
</style>
