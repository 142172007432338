<template>
	<article>
		<Men />
	</article>
</template>

<script>
	import Men from '@/components/Men.vue'

	export default {
		name: 'Man',

		data() {
			return {}
		},

		components: {
			Men,
		},

		methods: {},
		computed: {},
	}
</script>

<style scoped lang="scss">
	article {
		width: 100%;
		height: auto;

		@media (max-width: 480px) {
			width: auto;
			max-width: 480px;
			height: auto;
			margin: 0 auto;
		}
	}
</style>
