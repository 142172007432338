<template>
  <article class="col-12-xs col-12-sm col-12-mm col-12-md col-12-lg col-12-xl col-12-xx p-3">
      <h2 class="h2">{{this.$route.meta.title}}</h2>

      <p class="lead first">
          Je gezicht laten ontharen met touw wordt ook wel threading genoemd of Oosters ontharen. Dat komt omdat deze schoonheidsbehandeling origineel door Arabische en Indische vrouwen werd gebruikt. Met een gespannen touwtje of draad worden de haartjes op je gezicht snel verwijderd.
      </p>

      
  </article>
</template>

<script>
export default {
  name: "GezichtEpileren",


};
</script>
