<template>
	<section class="mt-1">
		<h2>Afspraak voorwaarden</h2>
		<p class="">
			Als u een afspraak bij ons hebt en deze wil afzeggen dan gelden de
			volgende regels:
		</p>
		<ul class="mt-1">
			<li class="li">
				Annuleert u 24 uur vóór de afspraak dan betaalt u 30% van de
				behandeling.
			</li>
			<li class="li">
				Anuuleert u op de dag van de afspraak dan betaalt u de gehele
				behandeling.
			</li>
		</ul>
	</section>

	<h2>Onze tarieven</h2>
	<article class=" justify-center row">
		<Tarief naam="Vrouwen - Wassen, knippen & drogen" prijs="39,50" />
		<Tarief naam="Mannen - Knippen & tondeuse" prijs="33,50" />
		<Tarief naam="Kinderen t/m 14 jaar -Knippen" prijs="29" />
		<Tarief naam="Gezicht Epileren ( met wenkbrauwen )" prijs="30" />
		<Tarief naam="Botox Behandeling (vitamine) vanaf" prijs="45" />
		<Tarief naam="Keratine Behandeling vanaf" prijs="120" />
		<Tarief naam="Verven - Wenkbrauwen" prijs="10" />
		<Tarief naam="Verven - Wimpers & Wenkbrauwen" prijs="25" />
		<Tarief
			naam="Vrouwen - Wassen & haar steilen & model föhnen vanaf"
			prijs="30"
		/>
		<Tarief naam="Vrouwen - Wassen  vanaf" prijs="30" />
		<Tarief naam="Wimperlifting" prijs="60" />
		<Tarief naam="Mannen - Designer haircut" prijs="37,50" />
		<Tarief naam="Blonderen - Wenkbrauwen" prijs="20" />
	</article>

	<Icons />
</template>

<script>
	import Tarief from '@/components/Tarief.vue'
	import Icons from '@/components/Icons.vue'
	export default {
		name: 'Tarieven',

		components: {
			Tarief,
			Icons,
		},
	}
</script>

<style scoped lang="scss">
	article {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 0 5px;
		padding: 20px;

		@media (max-width: 480px) {
			grid-template-columns: auto;
			width: 100%;
			max-width: 480px;
		}
	}

	section {
		padding: 20px;
	}

	.li {
		list-style-type: square;
		font-weight: bold;
	}
</style>
