import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Over from '@/views/Over.vue'
import Tarieven from '@/views/Tarieven.vue'
import Contact from '@/views/Contact.vue'
import Man from '@/views/Man.vue'
import Vrouw from '@/views/Vrouw.vue'
import Kind from '@/views/Kind.vue'
import Highlight from '@/views/Highlight.vue'
import Verven from '@/views/Verven.vue'
import Knippen from '@/views/Knippen.vue'
import Epileren from '@/views/Epileren.vue'
import Keratine from '@/components/Keratine.vue'
import Volume from '@/components/Volume.vue'
import GezichtEpileren from '@/components/GezichtEpileren.vue'
import Hana from '@/components/Hana.vue'
import Extentions from '@/components/Extentions.vue'
// import { saveUser } from '@/data/db'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: 'Home',
			// verPlicht: true,
		},
	},
	{
		path: '/login',
		name: 'Login',
		component: () =>
			import(/* webpackChunkName: 'Login' */ '@/views/Login.vue'),
		meta: {
			title: 'Login',
		},
	},
	{
		path: '/register',
		name: 'Register',
		component: () =>
			import(/* webpackChunkName: 'Register' */ '@/views/Register.vue'),
		meta: {
			title: 'Register',
		},
	},

	{
		path: '/over',
		name: 'Over',
		component: Over,
		meta: {
			title: 'Over',
			// verPlicht: true,
		},
	},
	{
		path: '/tarieven',
		name: 'Tarieven',
		component: Tarieven,
		meta: {
			title: 'Tarieven',
			// verPlicht: true,
		},
	},
	{
		path: '/man',
		name: 'Man',
		component: Man,
		meta: {
			title: 'Man',
			// verPlicht: true,
		},
	},
	{
		path: '/vrouw',
		name: 'Vrouw',
		component: Vrouw,
		meta: {
			title: 'Vrouw',
			// verPlicht: true,
		},
	},
	{
		path: '/botox',
		name: 'Botox',
		component: () =>
			import(/* webpackChunkName: "Botax" */ '@/components/Botox.vue'),
		meta: {
			title: 'Botox',
			// verPlicht: true,
		},
	},
	{
		path: '/gezicht-epileren',
		name: 'Gezicht Epileren',
		component: GezichtEpileren,
		meta: {
			title: 'Gezicht Epileren',
			// verPlicht: true,
		},
	},
	{
		path: '/epileren',
		name: 'Epileren',
		component: Epileren,
		meta: {
			title: 'Epileren',
			// verPlicht: true,
			
		},
	},
	{
		path: '/keratine',
		name: 'Keratine',
		component: Keratine,
		meta: {
			title: 'Keratine',
			// verPlicht: true,
		},
	},
	{
		path: '/volume-lash-lifting',
		name: 'Volume',
		component: Volume,
		meta: {
			title: 'Volume Lash Lifting',
			// verPlicht: true,
		},
	},
	{
		path: '/hena',
		name: 'Hena',
		component: Hana,
		meta: {
			title: 'Hena',
			// verPlicht: true,
		},
	},
	{
		path: '/extentions',
		name: 'Extentions',
		component: Extentions,
		meta: {
			title: 'Extentions',
			// verPlicht: true,
		},
	},
	{
		path: '/kind',
		name: 'Kind',
		component: Kind,
		meta: {
			title: 'Kind',
			// verPlicht: true,
		},
	},
	{
		path: '/highlight',
		name: 'Highlight', 
		component: Highlight,
		meta: {
			title: 'Highlight',
			// verPlicht: true,
		},
	},
	{
		path: '/verven',
		name: 'Verven',
		component: Verven,
		meta: {
			title: 'Verven',
			// verPlicht: true,
		},
	},
	{
		path: '/knippen',
		name: 'Knippen',
		component: Knippen,
		meta: {
			title: 'Knippen',
			// verPlicht: true,
		},
	},
	{
		path: '/contact',
		name: 'Contact',
		component: Contact,
		meta: {
			title: 'Contact',
			// verPlicht: true,
		},
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

// Voor Dynamic title of page
router.beforeEach((to, from, next) => {
	const docT = `${to.meta.title} - Gazelle Beauty`
	document.title = docT
	next()
})

// Voor Auth
// router.beforeEach((to, from, next) => {
// 	// Maak een zibaar vanuit meta
// 	const verPlicht = to.matched.some(rec => rec.meta.verPlicht)
//
// 	// Maak bekend auth is of niet
// 	const isAuthenticated = saveUser.currentUser
//
// 	// Als verplicht is en niet auth
// 	if (verPlicht && !isAuthenticated) {
// 		// Terug naar login pagina
// 		next({name: 'Home'})
// 	} else {
// 		next()
// 	}
// })

export default router
