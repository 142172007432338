<template>
	<article>
		<Overmij />
	</article>
</template>

<script>
	import Overmij from '@/components/OverMij.vue'

	export default {
		name: 'Over',

		data() {
			return {}
		},

		components: {
			Overmij,
		},
	}
</script>

<style scoped lang="scss">
	article {
		width: 100%;
		height: auto;

		@media (max-width: 480px) {
			grid-template-columns: 1fr;
			width: 100%;
			max-width: 480px;
		}
	}
</style>
