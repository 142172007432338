<template>
	<article class="">
		<section
			class="col-12-xs col-12-sm col-12-mm col-12-md col-12-lg col-12-xl col-12-xx p-3 "
		>
			<div class="bg-info-licht-8 row">
				<h1 class="h2 justify-center p-1 text-dark bg-info-licht-8">
					Henna behandeling
				</h1>
				<p class="lead first ">
					Henna Brows is anders dan gewoon verven omdat je niet alleen
					de haren maar ook de huid verft. Hierdoor lijken de
					wenkbrauwen voller en hoef je niet na een week weer zelf met
					een potlood of poeder aan de slag. De henna zorgt voor
					strakkere en vollere wenkbrauwen
				</p>
			</div>
		</section>
	</article>
</template>

<script>
	export default {
		name: 'Hana',
	}
</script>

<style scoped lang="scss">
	article {
		background-image: url('../assets/img/Henna.jpg');
		width: 100%;
		min-height: 90vh;
		background-attachment: center;
		background-origin: content-box;
		background-position: center center;
	}
</style>
