import { db } from '@/data/db'


// Firestore path
const KarbarCollection = 'karbar'

export default new (class GazelService {
	// Alle players
	getKarbar() {
		return db.collection(KarbarCollection)
	}
	// Single Player
	eenKarbar(id) { 
		return db
			.collection(KarbarCollection)
			.doc(id)
			.get() 
		/*return player .exists ? player.data() : null */
	}
	// Een Player Toevoegen
	addKarbar(karbar) {
		return db.collection(KarbarCollection).add(karbar)
	}
	// Update een Player
	updateKarbar(player, id) {
		return db
			.collection(KarbarCollection)
			.doc(id)
			.update(player)
			.then(() => {
				console.log('Ane update shod')
			})
	}
	deletKarbar(id) {
		return db
			.collection(KarbarCollection)
			.doc(id)
			.delete()
	}
})()