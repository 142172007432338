<template>
	<!-- Hoofd component -->
	<article
		class="carousel hi-90  col-12-xs col-12-sm col-12-mm col-12-md col-12-lg col-12-xl col-12-xx"
	>
		<!-- Tazrighe mohtawa az raweshe Slot -->
		<slot :currentSlide="currentSlide" />

		<!-- Navigation Arrows -->
		<section class="navigatie" v-if="nav">
			<div class="iconn left">
				<span class="bg-primary br-ful">
					<i class="fa fa-chevron-left " @click="prevSlide()"></i>
				</span>
			</div>
			<div class="iconn right">
				<span class=" br-ful bg-primary">
					<i class="fa fa-chevron-right" @click="nextSlide()"></i>
				</span>
			</div>
		</section>

		<!-- Pagination / Bullet -->
		<section class="pagination" v-if="pagin">
			<!-- Bind a class for active bullet -->
			<!-- If huidige value is gelijk aan currentSlide -->
			<span
				@click="toSlide(i)"
				v-for="(slide, i) in getSlideCount"
				:key="i"
				:class="{ active: i + 1 === currentSlide }"
			>
			</span>
		</section>
	</article>
</template>

<script>
	import { ref } from 'vue'
	export default {
		name: 'Carousel',

		data() {
			return {
				// Var baraye mohtawaye reference
				currentSlide: ref(1),
				// Var baraye shomareshe reference
				getSlideCount: ref(null),
				// Var baraye Default duration
				timeDuration: ref(this.time),
			}
		},

		props: {
			pagin: {
				type: Boolean,
				required: true,
				default: true,
			},
			nav: {
				type: Boolean,
				required: true,
				default: true,
			},
			play: {
				type: Boolean,
				required: true,
				default: true,
			},
			time: {
				type: Number,
				required: true,
				default: 5000,
			},
		},

		mounted() {
			this.getValue()
			this.autoPlay()
		},

		methods: {
			// Gereftane element ha ba class wa aanra be yek var tabdil kardan
			getValue() {
				this.getSlideCount = document.querySelectorAll('.slide').length
				console.log(`Het huidige aantal foto's ${this.getSlideCount}`)
			},

			// Previos Image
			prevSlide() {
				if (this.currentSlide === 1) {
					this.currentSlide = this.getSlideCount - 1
					return
				}
				this.currentSlide -= 1
			},

			// Next Image
			nextSlide() {
				if (this.currentSlide === this.getSlideCount) {
					this.currentSlide = 1
					return
				}
				this.currentSlide += 1
			},

			// Bullets function
			toSlide(i) {
				this.currentSlide = i + 1
			},

			// AutoPlay Function
			autoPlay() {
				setInterval(() => {
					// Call next
					if (this.play) {
						this.nextSlide()
					}
					return
				}, this.timeDuration)
			},
		},
	}
</script>

<style lang="scss" scoped>
	.carousel {
		z-index: 0;
		width: auto;
		max-width: 100%;
		height: 90vh;
		max-height: 90vh;

		@media (max-width: 480px) {
			margin-bottom: -100px;
		}

		.navigatie {
			padding: 0;
			height: 100%;
			width: 95%;
			max-width: 100%;
			position: absolute;
			display: flex;
			margin-left: 30px;
			justify-content: center;
			align-items: center;

			@media (max-width: 480px) {
				width: auto;
				max-width: 480px;
				bottom: 40px;
				margin-left: 7px;
			}

			.iconn {
				display: flex;
				flex: 1;
				z-index: 999;

				@media (max-width: 480px) {
					padding: 5px;
					display: none;
				}
			}
			.right {
				justify-content: flex-end;
				@media (max-width: 480px) {
					display: none;
				}
			}

			i {
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				background-color: $navColor;
				border: none;
				color: #000000;
				width: 50px;
				height: 50px;
				font-size: 22px;

				&:hover {
					transform: scale(.8);
					color: $light;
					transition: all .5s  ease-in-out;
				}

				@media (max-width: 480px) {
					font-size: 18px;
					width: 35px;
					height: 35px;
				}
			}
		}

		.pagination {
			position: absolute;
			padding: 25px;
			width: 100%;
			max-width: 100%;
			display: flex;
			gap: 15px;
			justify-content: center;
			align-items: center;
			bottom: 0;
			@media (max-width: 480px) {
				width: auto;
				max-width: 400px;
				height: 100%;
				top: 150px;
				margin: 0 auto;
				position: relative;
			}

			span {
				cursor: pointer;
				width: 25px;
				height: 25px;
				border-radius: 50%;
				background-color: rgb(0, 0, 0);
			}

			.active {
				background-color: var(--navColor);
				border: none;
			}
		}
	}
</style>
