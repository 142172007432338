<template>
	<ul>
		<li v-for="(item, i) in items" :key="i">
			<router-link :to="{ name: `${item.link}` }" class="nav-link">
				{{ item.title }}
			</router-link>
		</li>
	</ul>
</template>

<script>
	export default {
		name: 'DropMenuItem',

		props: {
			title: {
				type: String,
			},
			items: {
				type: Array,
			},
		},

		data() {
			return {
				isOpen: null,
			}
		},

		components: {},

		methods: {
			op() {
				this.isOpen = !this.isOpen
			},
		},
		watch: {
			$route() {
				this.isOpen = false
			},
		},
		computed: {},
	}
</script>

<style scoped lang="scss">
	.nav-link {
		text-align: center;
		display: flex;
	}

	// 	.menu {
	// 		position: absolute;
	// 		width: 200px;
	// 		height: auto;
	//
	// 		margin-left: -65px;
	// 		z-index: 9;
	// 	}
	// 	.sub-menu {
	// 		position: absolute;
	// 		background-color: var(--primary-color);
	// 		width: max-content;
	// 		padding: 20px;
	// 		z-index: 99;
	// 		height: auto;
	// 		margin-left: -10px;
	// 		margin-top: -10px;
	// 		border-radius: 7px;
	//
	// 		.menu-item {
	// 			color: var(--dark-color);
	// 			margin: 10px auto;
	// 			padding: 10px;
	// 			z-index: 999;
	// 			display: flex;
	// 			font-size: 17.5px;
	// 			font-weight: normal;
	// 			width: max-content;
	//
	// 			&:hover {
	// 				background-color: var(--derde-color);
	// 				color: var(--light-color);
	// 			}
	// 		}
	// 	}
</style>
