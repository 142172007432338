<template>
	<article class="row justify-center p-3 ">
		<section
			class="col-12-xs col-12-sm col-12-mm col-6-md col-6-lg col-6-xl col-6-xx"
		>
			<h2 class="text-primary h2">Ook kinderen zijn welkom</h2>
			<p class="lead p-2">
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio
				iste harum fuga amet quod ratione labore, ea inventore quam
				fugiat. Accusamus deserunt suscipit qui alias nostrum, iste vel
				quidem provident incidunt eveniet expedita ab sed, iusto aut
				veritatis dolorum eligendi saepe doloribus ipsum voluptatibus
				odit asperiores doloremque explicabo consectetur. Non
				perspiciatis voluptate sunt, illo, hic magni quasi debitis vitae
				ad aut quas voluptatum provident reiciendis impedit sit, sequi
				voluptas ullam ipsa quisquam. Corporis, eum architecto quia id
				officia ad voluptatibus possimus voluptate atque. Vel, rerum
				itaque nisi, officiis, voluptatem aperiam repellendus eos magnam
				amet perferendis fugit a libero consectetur placeat.
			</p>
		</section>
		<section
			class="col-12-xs col-12-sm col-12-mm col-6-md col-6-lg col-6-xl col-6-xx"
		>
			<img src="@/assets/img/Kind.jpg" class="img" alt="KindFoto" />
		</section>
	</article>
</template>

<script>
	export default {
		name: 'Kindd',
	}
</script>

<style lang="scss" scoped>
	@include xs {
		.text-primary {
			padding-bottom: 10px;
		}
	}

	@include md {
		.img {
			margin-top: 80px;
		}
	}

	@include xl {
		.img {
			margin-top: 250px;
		}
		.p {
			margin-top: 50px;
		}
	}

	@include Xbreakpoint {
		.text-primary {
			font-size: $xxl-font;
		}
		p {
			padding: 50px 70px;
			font-size: $xl-font;
		}
	}
</style>
