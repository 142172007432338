<template>
	<footer class="footer">
		<p>
			Alle rechten voorbehouden 
			<br> Gazelle Beauty &copy;2019&dash;
			{{ new Date().getFullYear() }}
		</p>
	</footer>
</template>

<style lang="scss" scoped>
	.footer {
		@media (max-width: 480px) {
			width: auto;
			max-width: 100%;
		}

		p {
			@media (max-width: 480px) {
				width: auto;
				max-width: 220px;
				margin: 0 auto;
			}
		}
	}
</style>
