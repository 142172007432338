<template>
	<article class="col-12-xs col-12-sm col-12-mm col-6-md col-6-lg col-6-xl col-6-xx justify-center p-3">
		<h2 class="h2">Keratine Behandeling</h2>
		<p class="lead first">
			Een keratinebehandeling is een semi-permanente treatment met
			vloeibare keratine die het haar glad en zacht maakt en bovendien
			diep voedt. En dat wel maandenlang! De keratinebehandeling is
			afkomstig uit het land van carnaval en Caipirinha's; Brazilië. De
			behandeling wordt daarom ook wel Brazilian blow-dry genoemd.
		</p>
	</article>
</template>

<script>
	export default {
		name: 'Keratine',

	}
</script>

<style scoped>
	.lead {
		padding: 30px;
	}
</style>
