<template>
	<article
		class="col-12-xs col-12-sm col-12-mm col-12-md col-12-lg col-12-xl col-12-xx p-3"
	>
		<h2 class="h2">Highlights</h2>
		<p class="lead first">{{txt}}</p>
	</article>
</template>

<script>
	export default {
		name: 'Highlight',

		data() {
			return {
				txt:
					'Highlights zijn eigenlijk gewoon verfstreepjes in je haar. Deze streepjes zijn lichter dan je eigen haarkleur. Juist door een paar tintjes lichter te gebruiken dan je eigen coupe, zorgen ze voor een fris sunkissed effect.',
			}
		},
	}
</script>


