import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Mix from '@/mixins'

// import 'font-awesome/css/font-awesome.min.css'

createApp(App)
	.use(store)
	.use(router)
	.mixin(Mix)
	.mount('#app')

// Als toegang voor hele app
// let app

/* saveUser.onAuthStateChanged(user => {
	console.log(user)
	if (!app) {
		app = createApp(App)
			.use(store)
			.use(router)
			.mount('#app')
	}
}) */
