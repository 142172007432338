<template>
	<article class="row justify-center p-3">
		<section
			class="col-12-xs col-12-sm col-12-mm col-6-md col-6-lg col-6-xl col-6-xx"
		>
			<h2 class="h2">Lash Lifting</h2>
			<p class="lead p-2 first">
				Lange wimpers zonder wimperextensions. Bij Volume Lash Lifting
				draait het om de eigen wimpers, die met behulp van siliconenpads
				worden gelift. Daardoor worden ze niet langer, maar líjken ze
				dat wel. Kortom: voortaan heb je geen wimperextensions meer
				nodig voor een eyecatching blik. Het oogt veel natuurlijker, is
				minder gedoe én is goedkoper dan eyelash extensions, doordat het
				resultaat wel tot acht weken zichtbaar is. Gebruik je ook nog
				mascara, dan is het effect echt smashing. Het
				valse-wimpereffect. Maar dan helemaal van jezelf.
			</p>
		</section>
		<section
			class="col-12-xs col-12-sm col-12-mm col-6-md col-6-lg col-6-xl col-6-xx"
		>
			<img src="@/assets/img/Makeup.jpg" alt="undraw" class="img" />
		</section>
	</article>
</template>

<script>
	export default {
		name: 'Volume',
	}
</script>

<style scoped></style>
