<template>
	<article>
		<Kindd />
	</article>
</template>

<script>
	import Kindd from '@/components/Kindd.vue'

	export default {
		name: 'Kind',

		data() {
			return {}
		},

		components: {
			Kindd,
		},

		methods: {},
		computed: {},
	}
</script>

<style scoped lang="scss">
	article {
		width: 100%;
		
	}
</style>
